@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-header {
  background-color: transparent;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.tables {
  margin-top: 100px;
  /* font-weight: bold; */
  font-size: 20px;
  /* Increase font size */
  color: black;
  /* Change font color to black */
  border: 1px solid black;
  /* Add border */
  border-radius: 10px;
  /* Round corners */
  padding: 10px;
  /* Increase padding to make box bigger */
  position: relative;
  /* Needed for positioning the tag */
  font-family: Arial, sans-serif;
  /* Change font to a more modern one */
}

.tables::before {
  content: "Table Names";
  position: absolute;
  top: -30px;
  left: 5px;
  background: white;
  padding: 2px 5px;
  border: 1px solid black;
  border-radius: 5px;
}

.answer {
  margin-top: 100px;
  font-weight: bold;
  font-size: 30px;
  color: black;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.answer::before {
  content: "Answer";
  position: absolute;
  top: -30px;
  left: 10px;
  background: white;
  padding: 2px 5px;
  border: 1px solid black;
  border-radius: 5px;
}

.logo {
  margin-bottom: 50px;
  margin-top: 50px;
  /* Adjust as needed */
}

.black-spinner .spinner-border {
  border-color: black;
  border-right-color: transparent;
}